import ot from "./browser-detector.js";
export function isTransparent(r) {
  return (r = parseInt(r)), !isNaN(r) && (4278190080 & r) >>> 24 == 0;
}
export function toRgba(r, t) {
  if (((r = parseInt(r)), isNaN(r))) return "";
  (t = parseFloat(t)), isNaN(t) && (t = 1);
  const n = 255 & (r >>>= 0),
    e = (65280 & r) >>> 8,
    o = (16711680 & r) >>> 16,
    a = ((4278190080 & r) >>> 24) / 255;
  return ot.Og()
    ? "rgba(" + [o, e, n, a * t].join(",") + ")"
    : "rgb(" + [o, e, n].join(",") + ")";
}
export function toRgb(r) {
  if (((r = parseInt(r)), isNaN(r))) return "";
  return (
    "rgb(" +
    [(16711680 & (r >>>= 0)) >>> 16, (65280 & r) >>> 8, 255 & r].join(",") +
    ")"
  );
}
