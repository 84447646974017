import K from "../managers/braze-instance.js";
import BrazeSdkMetadata from "./braze-sdk-metadata.js";
import {
  isArray as p,
  validateValueIsFromEnum as F
} from "../util/code-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function addSdkMetadata(a) {
  if (K.rr()) {
    if (!p(a))
      return (
        r.D.error("Cannot set SDK metadata because metadata is not an array."),
        !1
      );
    for (const t of a)
      if (
        !F(
          BrazeSdkMetadata,
          t,
          "sdkMetadata contained an invalid value.",
          "BrazeSdkMetadata"
        )
      )
        return !1;
    return K.nr().addSdkMetadata(a), !0;
  }
}
