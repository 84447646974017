export default class xt {
  constructor(t) {
    this.Jl = t;
  }
  Hl(t) {
    return null == this.Jl || this.Jl === t[0];
  }
  static fromJson(t) {
    return new xt(t ? t.event_name : null);
  }
  ss() {
    return this.Jl;
  }
}
