import {
  Card,
  Banner,
  CaptionedImage,
  ClassicCard,
  ControlCard
} from "../index.js";
import {
  dateFromUnixTimestamp as h,
  rehydrateDateAfterJsonization as w
} from "../../util/date-utils.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export function newCard(e, n, t, o, i, l, u, d, a, s, w, f, m, C, p, c, x, F) {
  let b;
  if (n === Card.es.dt || n === Card.es.Rt)
    b = new ClassicCard(e, t, o, i, l, u, d, a, s, w, f, m, C, p, c, x);
  else if (n === Card.es.st)
    b = new CaptionedImage(e, t, o, i, l, u, d, a, s, w, f, m, C, p, c, x);
  else if (n === Card.es.rs)
    b = new Banner(e, t, i, u, d, a, s, w, f, m, C, p, c, x);
  else {
    if (n !== Card.es._t)
      return r.D.error("Ignoring card with unknown type " + n), null;
    b = new ControlCard(e, t, d, s, C, p);
  }
  return F && (b.test = F), b;
}
export function newCardFromContentCardsJson(e) {
  if (e[Card.At.Dt]) return null;
  const n = e[Card.At.ns],
    r = e[Card.At.ts],
    t = e[Card.At.ls],
    o = e[Card.At.ht],
    i = e[Card.At.os],
    l = e[Card.At.rt],
    u = h(e[Card.At.us]),
    d = u;
  let a;
  a = e[Card.At.ps] === Card.Ct ? null : h(e[Card.At.ps]);
  return newCard(
    n,
    r,
    t,
    o,
    i,
    l,
    d,
    u,
    null,
    a,
    e[Card.At.URL],
    e[Card.At.bs],
    e[Card.At.fs],
    e[Card.At.xs],
    e[Card.At.js],
    e[Card.At.zs],
    e[Card.At.gs],
    e[Card.At.ks] || !1
  );
}
export function newCardFromFeedJson(e) {
  return newCard(
    e.id,
    e.type,
    e.viewed,
    e.title,
    e.image,
    e.description,
    h(e.created),
    h(e.updated),
    e.categories,
    h(e.expires_at),
    e.url,
    e.domain,
    e.aspect_ratio,
    e.extras,
    !1,
    !1
  );
}
export function newCardFromSerializedValue(e) {
  return (
    newCard(
      e[Card.hs.ns],
      e[Card.hs.ts],
      e[Card.hs.ls],
      e[Card.hs.ht],
      e[Card.hs.os],
      e[Card.hs.rt],
      w(e[Card.hs.cs]),
      w(e[Card.hs.us]),
      e[Card.hs.ds],
      w(e[Card.hs.ps]),
      e[Card.hs.URL],
      e[Card.hs.bs],
      e[Card.hs.fs],
      e[Card.hs.xs],
      e[Card.hs.js],
      e[Card.hs.zs],
      e[Card.hs.gs],
      e[Card.hs.ks] || !1
    ) || void 0
  );
}
