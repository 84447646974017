export default {
  GOOGLE_TAG_MANAGER: "gg",
  MPARTICLE: "mp",
  SEGMENT: "sg",
  TEALIUM: "tl",
  MANUAL: "manu",
  NPM: "npm",
  CDN: "wcd",
  SHOPIFY: "shp"
};
