import Se from "./base-device-parser.js";
import { Browsers as ri, OperatingSystems as rt } from "./device-constants.js";
export default class ni extends Se {
  constructor() {
    super(), (this.Su = ni.Vu(navigator.userAgent || ""));
  }
  tf() {
    return this.Su[0] || "Unknown Browser";
  }
  ef() {
    return this.Su[1] || "Unknown Version";
  }
  Zo(r) {
    for (let e = 0; e < r.length; e++) {
      let n = r[e].string,
        i = ni.rf(n, r[e]);
      if (i)
        return (
          i === rt.jg && navigator.maxTouchPoints > 1 && (i = rt.ln),
          Promise.resolve(i)
        );
    }
    return Promise.resolve(navigator.platform);
  }
  static Vu(r) {
    let e,
      n =
        r.match(
          /(samsungbrowser|tizen|roku|konqueror|icab|crios|opera|ucbrowser|chrome|safari|firefox|camino|msie|trident(?=\/))\/?\s*(\.?\d+(\.\d+)*)/i
        ) || [];
    if (/trident/i.test(n[1]))
      return (
        (e = /\brv[ :]+(\.?\d+(\.\d+)*)/g.exec(r) || []), [ri.Pg, e[1] || ""]
      );
    if (-1 !== r.indexOf("(Web0S; Linux/SmartTV)"))
      return ["LG Smart TV", null];
    if (-1 !== r.indexOf("CrKey")) return ["Chromecast", null];
    if (
      -1 !== r.indexOf("BRAVIA") ||
      -1 !== r.indexOf("SonyCEBrowser") ||
      -1 !== r.indexOf("SonyDTV")
    )
      return ["Sony Smart TV", null];
    if (-1 !== r.indexOf("PhilipsTV")) return ["Philips Smart TV", null];
    if (r.match(/\b(Roku)\b/)) return ["Roku", null];
    if (r.match(/\bAFTM\b/)) return ["Amazon Fire Stick", null];
    if (
      n[1] === ri.rE &&
      ((e = r.match(/\b(OPR|Edge|EdgA|Edg|UCBrowser)\/(\.?\d+(\.\d+)*)/)),
      null != e)
    )
      return (
        (e = e.slice(1)),
        (e[0] = e[0].replace("OPR", ri.EE)),
        (e[0] = e[0].replace("EdgA", ri.eE)),
        "Edg" === e[0] && (e[0] = ri.eE),
        [e[0], e[1]]
      );
    if (
      n[1] === ri.kg &&
      ((e = r.match(/\b(EdgiOS)\/(\.?\d+(\.\d+)*)/)), null != e)
    )
      return (
        (e = e.slice(1)), (e[0] = e[0].replace("EdgiOS", ri.eE)), [e[0], e[1]]
      );
    if (
      ((n = n[2] ? [n[1], n[2]] : [null, null]),
      n[0] === ri.kg &&
        null != (e = r.match(/version\/(\.?\d+(\.\d+)*)/i)) &&
        n.splice(1, 1, e[1]),
      null != (e = r.match(/\b(UCBrowser)\/(\.?\d+(\.\d+)*)/)) &&
        n.splice(1, 1, e[2]),
      n[0] === ri.EE && null != (e = r.match(/mini\/(\.?\d+(\.\d+)*)/i)))
    )
      return ["Opera Mini", e[1] || ""];
    if (n[0]) {
      const r = n[0].toLowerCase();
      "msie" === r && (n[0] = ri.Pg),
        "crios" === r && (n[0] = ri.rE),
        "tizen" === r && ((n[0] = "Samsung Smart TV"), (n[1] = null)),
        "samsungbrowser" === r && (n[0] = "Samsung Browser");
    }
    return n;
  }
}
