import InAppMessage from "./in-app-message.js";
import { DOMUtils as vt } from "../../util/dom-utils.js";
export default class SlideUpMessage extends InAppMessage {
  constructor(t, e, o, s, n, i, r, d, u, a, p, l, m, c, v, x, g, f, h, I, M) {
    (x = x || InAppMessage.xi.sh),
      (v = v || InAppMessage.xi.th),
      super(
        t,
        (e = e || InAppMessage.TextAlignment.START),
        o,
        s,
        n,
        i,
        r,
        d,
        u,
        a,
        p,
        l,
        null,
        m,
        c,
        v,
        x,
        g,
        f,
        h,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        I,
        M
      );
  }
  Me() {
    return !1;
  }
  get Ce() {
    return InAppMessage.TextAlignment.START;
  }
  Ae() {
    const t = document.createElement("span");
    return t.appendChild(document.createTextNode(this.message)), t;
  }
  Yi(t) {
    const e = t.getElementsByClassName("ab-in-app-message")[0];
    vt.eo(e, !0, !0) ||
      (this.slideFrom === InAppMessage.SlideFrom.TOP
        ? (e.style.top = "0px")
        : (e.style.bottom = "0px")),
      super.Yi(t);
  }
}
SlideUpMessage.es = InAppMessage.es.oh;
