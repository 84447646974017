import r from "../../shared-lib/braze-shared-lib.js";
import zt from "../common/translations.js";
export default class nr {
  constructor(t, l) {
    if ((null != t && (t = t.toLowerCase()), null != t && null == zt[t])) {
      const l = t.indexOf("-");
      l > 0 && (t = t.substring(0, l));
    }
    if (null == zt[t]) {
      const a =
        "Braze does not yet have a localization for language " +
        t +
        ", defaulting to English. Please contact us if you are willing and able to help us translate our SDK into this language.";
      l ? r.D.error(a) : r.D.info(a), (t = "en");
    }
    this.language = t;
  }
  get(t) {
    return zt[this.language][t];
  }
}
