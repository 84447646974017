import e from "../managers/braze-instance.js";
import { getByteLength as U } from "../util/string-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { User } from "../User/index.js";
import { validateStandardString as R } from "../util/validation-utils.js";
export function changeUser(s, i) {
  return (
    !!e.rr() &&
    (null == s || 0 === s.length || s != s
      ? (r.D.error("changeUser requires a non-empty userId."), !1)
      : U(s) > User.ee
      ? (r.D.error(
          `Rejected user id "${s}" because it is longer than ${User.ee} bytes.`
        ),
        !1)
      : !(null != i && !R(i, "set signature for new user", "signature")) &&
        void e.mr().changeUser(s.toString(), e.re(), i))
  );
}
