import r from "../../shared-lib/braze-shared-lib.js";
export default class u {
  constructor() {
    this.Pe = {};
  }
  ut(t) {
    if ("function" != typeof t) return null;
    const i = r.it.nt();
    return (this.Pe[i] = t), i;
  }
  removeSubscription(t) {
    delete this.Pe[t];
  }
  removeAllSubscriptions() {
    this.Pe = {};
  }
  bu() {
    return Object.keys(this.Pe).length;
  }
  St(t) {
    const i = [];
    for (let r in this.Pe) i.push(this.Pe[r](t));
    return i;
  }
}
