import Card from "./card.js";
export default class CaptionedImage extends Card {
  constructor(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f) {
    super(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f),
      (this.Y = "ab-captioned-image"),
      (this.Z = !0);
  }
  ss() {
    const t = {};
    return (
      (t[Card.hs.ts] = Card.es.st),
      (t[Card.hs.ns] = this.id),
      (t[Card.hs.ls] = this.viewed),
      (t[Card.hs.ht] = this.title),
      (t[Card.hs.os] = this.imageUrl),
      (t[Card.hs.rt] = this.description),
      (t[Card.hs.us] = this.updated),
      (t[Card.hs.cs] = this.created),
      (t[Card.hs.ds] = this.categories),
      (t[Card.hs.ps] = this.expiresAt),
      (t[Card.hs.URL] = this.url),
      (t[Card.hs.bs] = this.linkText),
      (t[Card.hs.fs] = this.aspectRatio),
      (t[Card.hs.xs] = this.extras),
      (t[Card.hs.js] = this.pinned),
      (t[Card.hs.zs] = this.dismissible),
      (t[Card.hs.gs] = this.clicked),
      (t[Card.hs.ks] = this.test),
      t
    );
  }
}
