import DeviceProperties from "../Core/device-properties.js";
export default class Ie {
  constructor(s) {
    this.id = s;
  }
  Mi() {
    const s = {};
    return (
      null != this[DeviceProperties.BROWSER] &&
        (s.browser = this[DeviceProperties.BROWSER]),
      null != this[DeviceProperties.BROWSER_VERSION] &&
        (s.browser_version = this[DeviceProperties.BROWSER_VERSION]),
      null != this[DeviceProperties.OS] &&
        (s.os_version = this[DeviceProperties.OS]),
      null != this[DeviceProperties.RESOLUTION] &&
        (s.resolution = this[DeviceProperties.RESOLUTION]),
      null != this[DeviceProperties.LANGUAGE] &&
        (s.locale = this[DeviceProperties.LANGUAGE]),
      null != this[DeviceProperties.TIME_ZONE] &&
        (s.time_zone = this[DeviceProperties.TIME_ZONE]),
      null != this[DeviceProperties.USER_AGENT] &&
        (s.user_agent = this[DeviceProperties.USER_AGENT]),
      s
    );
  }
}
