import Card from "./card.js";
export default class ControlCard extends Card {
  constructor(l, t, s, n, i, u) {
    super(
      l,
      t,
      null,
      null,
      null,
      null,
      s,
      null,
      n,
      null,
      null,
      null,
      i,
      u,
      null
    ),
      (this.isControl = !0),
      (this.Y = "ab-control-card"),
      (this.Z = !1);
  }
  ss() {
    const l = {};
    return (
      (l[Card.hs.ts] = Card.es._t),
      (l[Card.hs.ns] = this.id),
      (l[Card.hs.ls] = this.viewed),
      (l[Card.hs.us] = this.updated),
      (l[Card.hs.ps] = this.expiresAt),
      (l[Card.hs.xs] = this.extras),
      (l[Card.hs.js] = this.pinned),
      (l[Card.hs.ks] = this.test),
      l
    );
  }
}
