import x from "../common/base-feed.js";
import { logCardClick, logCardImpressions } from "../Card/index.js";
import { requestContentCardsRefresh } from "./request-content-cards-refresh.js";
export default class ContentCards extends x {
  constructor(r, e) {
    super(r, e);
  }
  getUnviewedCardCount() {
    return super.getUnreadCardCount();
  }
  logCardImpressions(r) {
    logCardImpressions(r, !0);
  }
  logCardClick(r) {
    logCardClick(r, !0);
  }
  sr() {
    requestContentCardsRefresh();
  }
  dr() {
    return !0;
  }
}
ContentCards.ur = 6e4;
