import InAppMessage from "./in-app-message.js";
export default class FullScreenMessage extends InAppMessage {
  constructor(
    e,
    t,
    r,
    s,
    n,
    l,
    u,
    a,
    o,
    p,
    m,
    c,
    d,
    f,
    g,
    i,
    x,
    j,
    A,
    T,
    b,
    h,
    k,
    q,
    v,
    w,
    y,
    z
  ) {
    (a = a || InAppMessage.DismissType.MANUAL),
      (w = w || InAppMessage.Orientation.PORTRAIT),
      super(
        e,
        t,
        null,
        r,
        s,
        n,
        l,
        u,
        a,
        o,
        p,
        m,
        c,
        d,
        f,
        g,
        i,
        x,
        j,
        A,
        T,
        b,
        h,
        k,
        q,
        (v = v || InAppMessage.CropType.CENTER_CROP),
        w,
        y,
        z
      );
  }
  get Ce() {
    return InAppMessage.TextAlignment.CENTER;
  }
}
FullScreenMessage.es = InAppMessage.es.Tr;
