import kt from "./filter-set.js";
export default class Dt {
  constructor(t, s) {
    (this.Jl = t), (this.Ql = s);
  }
  Hl(t) {
    if (null == this.Jl || null == this.Ql) return !1;
    const s = t[0],
      r = t[1];
    return s === this.Jl && this.Ql.Hl(r);
  }
  static fromJson(t) {
    return new Dt(
      t ? t.event_name : null,
      t ? kt.fromJson(t.property_filters) : null
    );
  }
  ss() {
    return { e: this.Jl, pf: this.Ql.ss() };
  }
}
