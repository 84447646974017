import ge from "./in-app-message-manager.js";
import e from "../managers/braze-instance.js";
const pe = {
  Oe: null,
  t: !1,
  m: () => (
    pe.o(), pe.Oe || (pe.Oe = new ge(e.nr(), e.g(), e.l(), e.p())), pe.Oe
  ),
  o: () => {
    pe.t || (e.u(pe), (pe.t = !0));
  },
  destroy: () => {
    (pe.Oe = null), (pe.t = !1);
  }
};
export default pe;
