import r from "../../shared-lib/braze-shared-lib.js";
export function values(t) {
  const e = [];
  for (let r in t) t.hasOwnProperty(r) && void 0 !== t[r] && e.push(t[r]);
  return e;
}
export function validateValueIsFromEnum(t, e, n, o) {
  const c = values(t);
  return (
    -1 !== c.indexOf(e) ||
    (r.D.error(`${n} Valid values from ${o} are "${c.join('"/"')}".`), !1)
  );
}
export function isArray(t) {
  return Array.isArray
    ? Array.isArray(t)
    : "[object Array]" === Object.prototype.toString.call(t);
}
export function isDate(t) {
  return "[object Date]" === Object.prototype.toString.call(t);
}
export function isObject(t) {
  return "[object Object]" === Object.prototype.toString.call(t);
}
export function intersection(t) {
  null == t && (t = []);
  const e = [],
    r = arguments.length;
  for (let n = 0, o = t.length; n < o; n++) {
    const o = t[n];
    if (-1 !== e.indexOf(o)) continue;
    let c = 1;
    for (c = 1; c < r && -1 !== arguments[c].indexOf(o); c++);
    c === r && e.push(o);
  }
  return e;
}
export function keys(t) {
  const e = [];
  for (let r in t) t.hasOwnProperty(r) && e.push(r);
  return e;
}
export function isEqual(t, e) {
  if (t === e) return 0 !== t || 1 / t == 1 / e;
  if (null == t || null == e) return t === e;
  const r = t.toString();
  if (r !== e.toString()) return !1;
  switch (r) {
    case "[object RegExp]":
    case "[object String]":
      return "" + t == "" + e;
    case "[object Number]":
      return +t != +t ? +e != +e : 0 == +t ? 1 / +t == 1 / e : +t == +e;
    case "[object Date]":
    case "[object Boolean]":
      return +t == +e;
  }
  const n = "[object Array]" === r;
  if (!n) {
    if ("object" != typeof t || "object" != typeof e) return !1;
    const r = t.constructor,
      n = e.constructor;
    if (
      r !== n &&
      !(
        "function" == typeof r &&
        r instanceof r &&
        "function" == typeof n &&
        n instanceof n
      ) &&
      "constructor" in t &&
      "constructor" in e
    )
      return !1;
  }
  const o = [],
    c = [];
  let i = o.length;
  for (; i--; ) if (o[i] === t) return c[i] === e;
  if ((o.push(t), c.push(e), n)) {
    if (((i = t.length), i !== e.length)) return !1;
    for (; i--; ) if (!isEqual(t[i], e[i], o, c)) return !1;
  } else {
    let r,
      n = keys(t);
    if (((i = n.length), keys(e).length !== i)) return !1;
    for (; i--; )
      if (((r = n[i]), !e.hasOwnProperty(r) || !isEqual(t[r], e[r], o, c)))
        return !1;
  }
  return o.pop(), c.pop(), !0;
}
