import r from "../../shared-lib/braze-shared-lib.js";
import { decodeBrazeActions as to } from "./string-utils.js";
import {
  BRAZE_ACTION_URI_REGEX as O,
  isValidBrazeActionType as oo,
  isValidBrazeActionJson as P
} from "./validation-utils.js";
export const BRAZE_ACTIONS = {
  types: {
    ue: "container",
    logCustomEvent: "logCustomEvent",
    setEmailNotificationSubscriptionType:
      "setEmailNotificationSubscriptionType",
    setPushNotificationSubscriptionType: "setPushNotificationSubscriptionType",
    setCustomUserAttribute: "setCustomUserAttribute",
    requestPushPermission: "requestPushPermission",
    addToSubscriptionGroup: "addToSubscriptionGroup",
    removeFromSubscriptionGroup: "removeFromSubscriptionGroup",
    addToCustomAttributeArray: "addToCustomAttributeArray",
    removeFromCustomAttributeArray: "removeFromCustomAttributeArray",
    de: "openLink",
    pe: "openLinkInWebView"
  },
  properties: { type: "type", me: "steps", fe: "args" }
};
export const INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES = {
  yi: "unknownBrazeAction",
  bi: "noPushPrompt"
};
export const ineligibleBrazeActionURLErrorMessage = (t, o) => {
  switch (t) {
    case INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES.yi:
      return `${o} contains an unknown braze action type and will not be displayed.`;
    case INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES.bi:
      return `${o} contains a push prompt braze action, but is not eligible for a push prompt. Ignoring.`;
    default:
      return "";
  }
};
export function getDecodedBrazeAction(t) {
  try {
    let o = t.match(O)[0].length;
    const e = t.substring(o);
    if (o > t.length - 1 || !e)
      return void r.D.error(
        `Did not find base64 encoded brazeAction in url to process : ${t}`
      );
    const n = to(e);
    return n
      ? JSON.parse(n)
      : void r.D.error(`Failed to decode base64 encoded brazeAction: ${e}`);
  } catch (o) {
    return void r.D.error(
      `Failed to process brazeAction URL ${t} : ${o.message}`
    );
  }
}
function ro(t, o) {
  let r = !1;
  for (const e of o) if (((r = r || t(e)), r)) return !0;
  return !1;
}
export function containsUnknownBrazeAction(t) {
  try {
    const o = t[BRAZE_ACTIONS.properties.type];
    return o === BRAZE_ACTIONS.types.ue
      ? ro(containsUnknownBrazeAction, t[BRAZE_ACTIONS.properties.me])
      : !oo(o);
  } catch (t) {
    return !0;
  }
}
export function containsPushPrimerBrazeAction(t) {
  if (!P(t)) return !1;
  const o = t[BRAZE_ACTIONS.properties.type];
  return o === BRAZE_ACTIONS.types.ue
    ? ro(containsPushPrimerBrazeAction, t[BRAZE_ACTIONS.properties.me])
    : o === BRAZE_ACTIONS.types.requestPushPermission;
}
