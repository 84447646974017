import e from "../managers/braze-instance.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
export function openSession() {
  if (!e.rr()) return;
  e.mr().openSession();
  const i = r.xt.Ft,
    o = new r.qt(i, r.D);
  o.hr(i.$t.jr, function(t, n) {
    const s = n.lastClick,
      c = n.trackingString;
    r.D.info(`Firing push click trigger from ${c} push click at ${s}`);
    const g = e.mr().kr(s, c),
      f = function() {
        W.er().je(V.vr, [c], g);
      };
    e.mr().zr(f, f), o.br(i.$t.jr, t);
  }),
    o.Jt(i.$t.$r, function(r) {
      e.mr().wr(r);
    });
}
