import InAppMessage from "./in-app-message.js";
export default class HtmlMessage extends InAppMessage {
  constructor(l, e, n, u, s, a, t, i, r, o, m) {
    (u = u || InAppMessage.DismissType.MANUAL),
      null != l &&
        l.length > 0 &&
        l.indexOf('"ab-in-app-message ab-html-message ab-email-capture"') > 0 &&
        l.indexOf('"ab-in-app-message ab-show ab-modal ab-effect-modal"') > 0 &&
        ((a = !0), (t = !0)),
      super(
        l,
        null,
        null,
        e,
        n,
        null,
        null,
        null,
        u,
        s,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        a,
        t,
        null,
        null,
        null,
        i,
        void 0,
        void 0,
        void 0,
        r,
        o
      ),
      (this.messageFields = m);
  }
  ll() {
    return !1;
  }
  k(l) {
    if (this.un === InAppMessage.es.el) {
      if (this.nl) return !1;
      this.nl = !0;
    }
    return this.lt.St(l), !0;
  }
}
HtmlMessage.es = InAppMessage.es.el;
