import e from "../managers/braze-instance.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function getDeviceId(i) {
  e.rr() &&
    (null == i &&
      r.D.error(
        "getDeviceId must be supplied with a callback. e.g., braze.getDeviceId(function(deviceId) {console.log('the device id is ' + deviceId)})"
      ),
    "function" == typeof i && i(e.ce().te().id));
}
