import e from "../managers/braze-instance.js";
import r from "../../shared-lib/braze-shared-lib.js";
import G, { STORAGE_KEYS as o } from "../managers/storage-manager.js";
export function disableSDK() {
  null != e.mr() && e.mr().requestImmediateDataFlush();
  const n = new G.ne(null, !0);
  n.store(o.ae, "This-cookie-will-expire-in-" + n.ie());
  const a = r.xt.Ft;
  new r.qt(a, r.D).setItem(a.$t.oe, a.se, !0), e.destroy(!1), e.le(!0);
}
