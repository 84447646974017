import { ControlCard } from "./models/index.js";
import s from "../common/event-logger.js";
import t from "../models/request-result.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
export default class a {
  constructor(s, t, r) {
    (this.h = s), (this.j = t), (this.v = r);
  }
  C(n, i) {
    const e = new t();
    if ((n.k(), null == n.url || "" === n.url))
      return (
        r.D.info(
          `Card ${n.id} has no url. Not logging click to Braze servers.`
        ),
        e
      );
    if (i) {
      const s = this.h.I(o.q.$) || {};
      (s[n.id] = !0), this.h.B(o.q.$, s);
    }
    const l = this.N([n]);
    if (null == l) return e;
    const u = i ? r.A._ : r.A.F;
    return s.G(u, l);
  }
  H(n) {
    const i = new t();
    if (!n.J())
      return (
        r.D.info(
          `Card ${n.id} refused this dismissal. Ignoring analytics event.`
        ),
        i
      );
    const e = this.h.I(o.q.K) || {};
    (e[n.id] = !0), this.h.B(o.q.K, e);
    const l = this.N([n]);
    return null == l ? i : s.G(r.A.L, l);
  }
  M(n, i) {
    const e = new t(!0),
      l = [],
      u = [];
    let a;
    a = i ? this.h.I(o.q.O) || {} : this.h.I(o.q.P) || {};
    for (const s of n)
      s.R(), s instanceof ControlCard ? u.push(s) : l.push(s), (a[s.id] = !0);
    const c = this.N(l),
      h = this.N(u);
    if (null == c && null == h) return (e.S = !1), e;
    if ((i ? this.h.B(o.q.O, a) : this.h.B(o.q.P, a), null != c)) {
      const t = i ? r.A.T : r.A.U,
        n = s.G(t, c);
      e.V(n);
    }
    if (null != h && i) {
      const t = s.G(r.A.W, h);
      e.V(t);
    }
    return e;
  }
  N(s) {
    let t = null;
    for (let r = 0; r < s.length; r++)
      null != s[r].id &&
        "" !== s[r].id &&
        ((t = t || {}), (t.ids = t.ids || []), t.ids.push(s[r].id));
    return t;
  }
}
