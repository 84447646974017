import a from "./card-manager.js";
import e from "../managers/braze-instance.js";
const n = {
  t: !1,
  i: null,
  m: () => (n.o(), n.i || (n.i = new a(e.l(), e.g(), e.p())), n.i),
  o: () => {
    n.t || (e.u(n), (n.t = !0));
  },
  destroy: () => {
    (n.i = null), (n.t = !1);
  }
};
export default n;
