import Card from "./card.js";
export default class ClassicCard extends Card {
  constructor(s, t, i, h, r, c, e, a, o, d, l, n, u, p, f, m) {
    super(s, t, i, h, r, c, e, a, o, d, l, n, u, p, f, m),
      (this.Y = "ab-classic-card"),
      (this.Z = !0);
  }
  ss() {
    const s = {};
    return (
      (s[Card.hs.ts] = Card.es.Rt),
      (s[Card.hs.ns] = this.id),
      (s[Card.hs.ls] = this.viewed),
      (s[Card.hs.ht] = this.title),
      (s[Card.hs.os] = this.imageUrl),
      (s[Card.hs.rt] = this.description),
      (s[Card.hs.us] = this.updated),
      (s[Card.hs.cs] = this.created),
      (s[Card.hs.ds] = this.categories),
      (s[Card.hs.ps] = this.expiresAt),
      (s[Card.hs.URL] = this.url),
      (s[Card.hs.bs] = this.linkText),
      (s[Card.hs.fs] = this.aspectRatio),
      (s[Card.hs.xs] = this.extras),
      (s[Card.hs.js] = this.pinned),
      (s[Card.hs.zs] = this.dismissible),
      (s[Card.hs.gs] = this.clicked),
      (s[Card.hs.ks] = this.test),
      s
    );
  }
}
