export function parseQueryStringKeyValues(t) {
  null == t && (t = "");
  const r = t
      .split("?")
      .slice(1)
      .join("?"),
    n = {};
  if (null != r) {
    const t = r.split("&");
    for (let r = 0; r < t.length; r++) {
      const a = t[r].split("=");
      "" !== a[0] && (n[a[0]] = a[1]);
    }
  }
  return n;
}
export function isURIJavascriptOrData(t) {
  return !(
    !t ||
    (0 !== (t = t.toString().toLowerCase()).lastIndexOf("javascript:", 0) &&
      0 !== t.lastIndexOf("data:", 0))
  );
}
