import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

function _isBugsnagEnabled() {
  return process.env.BUGSNAG_ENABLED === "true";
}

function isIgnored(err) {
  const ignoreListByMessage = [
    "Failed to fetch",
    "NetworkError when attempting to fetch resource.",
    "Token is expired",
    "We don't know you.",
    "Invalid token",
  ];
  const ignoreListByStatusCode = [401];
  let message;
  if (typeof err === "object") {
    message = JSON.stringify(err);
  }
  if (typeof err === "string") {
    message = err;
  }
  return (
    ignoreListByMessage.some((msg) => message?.includes(msg)) ||
    ignoreListByStatusCode.includes(err?.status || err?.statusCode)
  );
}

export function setUser({ id, email, name }) {
  if (_isBugsnagEnabled()) {
    Bugsnag.setUser(id, email, name);
  }
}

export function report(err) {
  // ignore errors that are in the blacklist so we don't overquota bugsnag with errors that are not actionable or are too frequent or we already send them to newrelic
  if (isIgnored(err)) {
    return;
  }
  if (_isBugsnagEnabled()) {
    let e;
    if (!err) {
      e = new Error();
    } else if (typeof err === "object") {
      e = new Error(JSON.stringify(err));
    } else {
      e = new Error(err);
    }
    Bugsnag.notify(e, (event) => {
      event.addMetadata("originalError", err);
      event.groupingHash = `${event.errors[0].errorClass}: ${event.errors[0].errorMessage} @ ${window.location.pathname}`;
    });
  }
}

// returns ErrorBoundary component to wrap the app
export function init() {
  if (_isBugsnagEnabled()) {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY_DASH_V2_WEB_APP,
      appVersion: process.env.BUGSNAG_APP_VERSION,
      releaseStage: process.env.BUGSNAG_APP_RELEASE_STAGE,
      plugins: [new BugsnagPluginReact()],
    });
    return Bugsnag.getPlugin("react").createErrorBoundary(React);
  } else {
    return class ErrorBoundary extends React.Component {
      constructor(props) {
        super(props);
        this.state = { hasError: false };
      }

      // eslint-disable-next-line no-unused-vars
      static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
      }

      componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("ERRROR");
        console.log(error, errorInfo);
      }

      render() {
        if (this.state.hasError) {
          return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
      }
    };
  }
}
