import e from "../managers/braze-instance.js";
import { MAX_PURCHASE_QUANTITY as Z } from "../common/constants.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as X,
  validateCustomString as Y
} from "../util/validation-utils.js";
import s from "../common/event-logger.js";
export function logPurchase(o, i, t, n, D) {
  if (!e.rr()) return !1;
  if (
    (null == t && (t = "USD"), null == n && (n = 1), null == o || o.length <= 0)
  )
    return (
      r.D.error(
        `logPurchase requires a non-empty productId, got "${o}", ignoring.`
      ),
      !1
    );
  if (!Y(o, "log purchase", "the purchase name")) return !1;
  let a = parseFloat(i);
  if (isNaN(a))
    return (
      r.D.error(`logPurchase requires a numeric price, got ${i}, ignoring.`), !1
    );
  a = a.toFixed(2);
  const g = parseInt(n);
  if (isNaN(g))
    return (
      r.D.error(
        `logPurchase requires an integer quantity, got ${n}, ignoring.`
      ),
      !1
    );
  if (g < 1 || g > Z)
    return (
      r.D.error(
        `logPurchase requires a quantity >1 and <${Z}, got ${g}, ignoring.`
      ),
      !1
    );
  t = t.toUpperCase();
  if (
    -1 ===
    [
      "AED",
      "AFN",
      "ALL",
      "AMD",
      "ANG",
      "AOA",
      "ARS",
      "AUD",
      "AWG",
      "AZN",
      "BAM",
      "BBD",
      "BDT",
      "BGN",
      "BHD",
      "BIF",
      "BMD",
      "BND",
      "BOB",
      "BRL",
      "BSD",
      "BTC",
      "BTN",
      "BWP",
      "BYR",
      "BZD",
      "CAD",
      "CDF",
      "CHF",
      "CLF",
      "CLP",
      "CNY",
      "COP",
      "CRC",
      "CUC",
      "CUP",
      "CVE",
      "CZK",
      "DJF",
      "DKK",
      "DOP",
      "DZD",
      "EEK",
      "EGP",
      "ERN",
      "ETB",
      "EUR",
      "FJD",
      "FKP",
      "GBP",
      "GEL",
      "GGP",
      "GHS",
      "GIP",
      "GMD",
      "GNF",
      "GTQ",
      "GYD",
      "HKD",
      "HNL",
      "HRK",
      "HTG",
      "HUF",
      "IDR",
      "ILS",
      "IMP",
      "INR",
      "IQD",
      "IRR",
      "ISK",
      "JEP",
      "JMD",
      "JOD",
      "JPY",
      "KES",
      "KGS",
      "KHR",
      "KMF",
      "KPW",
      "KRW",
      "KWD",
      "KYD",
      "KZT",
      "LAK",
      "LBP",
      "LKR",
      "LRD",
      "LSL",
      "LTL",
      "LVL",
      "LYD",
      "MAD",
      "MDL",
      "MGA",
      "MKD",
      "MMK",
      "MNT",
      "MOP",
      "MRO",
      "MTL",
      "MUR",
      "MVR",
      "MWK",
      "MXN",
      "MYR",
      "MZN",
      "NAD",
      "NGN",
      "NIO",
      "NOK",
      "NPR",
      "NZD",
      "OMR",
      "PAB",
      "PEN",
      "PGK",
      "PHP",
      "PKR",
      "PLN",
      "PYG",
      "QAR",
      "RON",
      "RSD",
      "RUB",
      "RWF",
      "SAR",
      "SBD",
      "SCR",
      "SDG",
      "SEK",
      "SGD",
      "SHP",
      "SLL",
      "SOS",
      "SRD",
      "STD",
      "SVC",
      "SYP",
      "SZL",
      "THB",
      "TJS",
      "TMT",
      "TND",
      "TOP",
      "TRY",
      "TTD",
      "TWD",
      "TZS",
      "UAH",
      "UGX",
      "USD",
      "UYU",
      "UZS",
      "VEF",
      "VND",
      "VUV",
      "WST",
      "XAF",
      "XAG",
      "XAU",
      "XCD",
      "XDR",
      "XOF",
      "XPD",
      "XPF",
      "XPT",
      "YER",
      "ZAR",
      "ZMK",
      "ZMW",
      "ZWL"
    ].indexOf(t)
  )
    return (
      r.D.error(
        `logPurchase requires a valid currencyCode, got ${t}, ignoring.`
      ),
      !1
    );
  const [u, P] = X(
    D,
    "logPurchase",
    "purchaseProperties",
    `log purchase "${o}"`,
    "purchase"
  );
  if (!u) return !1;
  if (e.ir().Dr(o))
    return r.D.info(`Purchase "${o}" is blocklisted, ignoring.`), !1;
  const R = s.G(r.A.Pr, { pid: o, c: t, p: a, q: g, pr: P });
  if (R.S) {
    r.D.info(
      `Logged ${g} purchase${g > 1 ? "s" : ""} of "${o}" for ${t} ${a}.`
    );
    for (const r of R.ve) W.er().je(V.Rr, [o, D], r);
  }
  return R.S;
}
