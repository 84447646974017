import { Card } from "./models/index.js";
import n from "./card-manager-factory.js";
import { MUST_BE_CARD_WARNING_SUFFIX as f } from "../common/constants.js";
import e from "../managers/braze-instance.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function logCardClick(o, a) {
  return (
    !!e.rr() &&
    (o instanceof Card ? n.m().C(o, a).S : (r.D.error("card " + f), !1))
  );
}
