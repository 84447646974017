import InAppMessage from "./in-app-message.js";
export default class ModalMessage extends InAppMessage {
  constructor(
    e,
    t,
    r,
    s,
    n,
    l,
    o,
    u,
    a,
    p,
    d,
    i,
    m,
    c,
    f,
    g,
    x,
    j,
    v,
    A,
    T,
    b,
    h,
    k,
    q,
    w,
    y
  ) {
    super(
      e,
      t,
      null,
      r,
      s,
      n,
      l,
      o,
      (u = u || InAppMessage.DismissType.MANUAL),
      a,
      p,
      d,
      i,
      m,
      c,
      f,
      g,
      x,
      j,
      v,
      A,
      T,
      b,
      h,
      k,
      (q = q || InAppMessage.CropType.FIT_CENTER),
      void 0,
      w,
      y
    );
  }
  get Ce() {
    return InAppMessage.TextAlignment.CENTER;
  }
}
ModalMessage.es = InAppMessage.es.lh;
