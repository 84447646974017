import Se from "./base-device-parser.js";
import { Browsers as ri } from "./device-constants.js";
export default class ti extends Se {
  constructor() {
    if (
      (super(),
      (this.userAgentData = navigator.userAgentData),
      this.userAgentData)
    ) {
      const t = this.ic();
      (this.browser = t.browser || "Unknown Browser"),
        (this.version = t.version || "Unknown Version");
    }
  }
  tf() {
    return this.browser;
  }
  ef() {
    return this.version;
  }
  Zo(t) {
    if (this.OS) return Promise.resolve(this.OS);
    const r = r => {
      for (let s = 0; s < t.length; s++) {
        const e = ti.rf(r, t[s]);
        if (e) return (this.OS = e), this.OS;
      }
      return r;
    };
    return this.userAgentData.platform
      ? Promise.resolve(r(this.userAgentData.platform))
      : this.nc()
          .then(t => r(t.platform))
          .catch(() => navigator.platform);
  }
  ic() {
    const t = {},
      r = this.userAgentData.brands;
    if (r && r.length)
      for (const s of r) {
        const r = this.oc(ri),
          e = s.brand.match(r);
        if (e && e.length > 0) {
          (t.browser = e[0]), (t.version = s.version);
          break;
        }
      }
    return t;
  }
  oc(t) {
    const r = [];
    for (const s in t) t[s] !== ri.Pg && r.push(t[s]);
    return new RegExp("(" + r.join("|") + ")", "i");
  }
  nc() {
    return this.userAgentData.getHighEntropyValues
      ? this.userAgentData.getHighEntropyValues(["platform"])
      : Promise.reject();
  }
}
