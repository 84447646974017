import InAppMessage from "./models/in-app-message.js";
import ControlMessage from "./models/control-message.js";
import FullScreenMessage from "./models/full-screen-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import ModalMessage from "./models/modal-message.js";
import SlideUpMessage from "./models/slide-up-message.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function newInAppMessageFromJson(e) {
  if (e.is_control) return ControlMessage.fromJson(e);
  let s = e.type;
  null != s && (s = s.toUpperCase());
  const o = e.message,
    m = e.text_align_message,
    l = e.slide_from,
    n = e.extras,
    t = e.trigger_id,
    i = e.click_action,
    p = e.uri,
    f = e.open_target,
    a = e.message_close,
    d = e.duration,
    u = e.icon,
    g = e.image_url,
    j = e.image_style,
    w = e.icon_color,
    c = e.icon_bg_color,
    b = e.bg_color,
    h = e.text_color,
    v = e.close_btn_color,
    I = e.header,
    k = e.text_align_header,
    x = e.header_text_color,
    y = e.frame_color,
    z = [];
  let A = e.btns;
  null == A && (A = []);
  for (let e = 0; e < A.length; e++) z.push(InAppMessageButton.fromJson(A[e]));
  const F = e.crop_type,
    J = e.orientation,
    M = e.animate_in,
    q = e.animate_out;
  let B,
    C = e.html_id,
    D = e.css;
  if (
    ((null != C && "" !== C && null != D && "" !== D) ||
      ((C = void 0), (D = void 0)),
    s === ModalMessage.es || s === InAppMessage.es.Ke)
  )
    B = new ModalMessage(
      o,
      m,
      n,
      t,
      i,
      p,
      f,
      a,
      d,
      u,
      g,
      j,
      w,
      c,
      b,
      h,
      v,
      M,
      q,
      I,
      k,
      x,
      y,
      z,
      F,
      C,
      D
    );
  else if (s === FullScreenMessage.es)
    B = new FullScreenMessage(
      o,
      m,
      n,
      t,
      i,
      p,
      f,
      a,
      d,
      u,
      g,
      j,
      w,
      c,
      b,
      h,
      v,
      M,
      q,
      I,
      k,
      x,
      y,
      z,
      F,
      J,
      C,
      D
    );
  else if (s === SlideUpMessage.es)
    B = new SlideUpMessage(
      o,
      m,
      l,
      n,
      t,
      i,
      p,
      f,
      a,
      d,
      u,
      g,
      w,
      c,
      b,
      h,
      v,
      M,
      q,
      C,
      D
    );
  else {
    if (s !== HtmlMessage.es && s !== InAppMessage.es.cn)
      return void r.D.error("Ignoring message with unknown type " + s);
    {
      const s = e.message_fields;
      (B = new HtmlMessage(o, n, t, a, d, M, q, y, C, D, s)),
        (B.trusted = e.trusted || !1);
    }
  }
  return (B.un = s), B;
}
