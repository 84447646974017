import b, { readResponseHeaders as C } from "../util/net.js";
import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import ContentCards from "./content-cards.js";
import { dateFromUnixTimestamp as h } from "../util/date-utils.js";
import { isURIJavascriptOrData as j } from "../util/url-utils.js";
import {
  newCardFromContentCardsJson as N,
  newCardFromSerializedValue as S
} from "../Card/util/card-factory.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
import s from "../common/event-logger.js";
export default class v extends y {
  constructor(t, s, i, h, n) {
    super(),
      (this.v = t),
      (this.h = s),
      (this.gt = i),
      (this.wt = h),
      (this.vt = n),
      (this.yt = new u()),
      e.jt(this.yt),
      (this.Ut = 0),
      (this.kt = 0),
      this.zt();
    const o = r.xt.Ft;
    new r.qt(o, r.D).Jt(o.$t.Mt, t => {
      this.Bt(t);
    }),
      (this.Gt = null);
  }
  Ht() {
    return this.Kt;
  }
  Qt(t) {
    this.Kt = t;
  }
  Vt() {
    return this.Wt;
  }
  Xt(t) {
    this.Wt = t;
  }
  zt() {
    const t = this.h.I(o.q.Yt) || [],
      s = [];
    for (let i = 0; i < t.length; i++) {
      const e = S(t[i]);
      null != e && s.push(e);
    }
    (this.Zt = this.Cs(this.ws(s, !1))),
      (this.Ut = this.h.I(o.q.vs) || this.Ut),
      (this.kt = this.h.I(o.q.ys) || this.kt);
  }
  Ns(t, s, i, e) {
    let h;
    if (s) {
      h = [];
      for (const t of this.Zt) t.test && h.push(t);
    } else h = this.Zt.slice();
    for (let i = 0; i < t.length; i++) {
      const e = t[i];
      let r = null;
      for (let t = 0; t < this.Zt.length; t++)
        if (e.id === this.Zt[t].id) {
          r = this.Zt[t];
          break;
        }
      if (s) {
        const t = N(e);
        null != r && r.viewed && (t.viewed = !0), null != t && h.push(t);
      } else if (null == r) {
        const t = N(e);
        null != t && h.push(t);
      } else {
        if (!r.ct(e))
          for (let t = 0; t < h.length; t++)
            if (e.id === h[t].id) {
              h.splice(t, 1);
              break;
            }
      }
    }
    (this.Zt = this.Cs(this.ws(h, s))),
      this.Ss(),
      (this.Ut = i || 0),
      this.h.B(o.q.vs, this.Ut),
      (this.kt = e || 0),
      this.h.B(o.q.ys, this.kt);
  }
  Rs(t) {
    if (this.Ts() && null != t && t.cards) {
      this.h.B(o.q._s, e.Ds());
      const s = t.full_sync;
      s || this.zt(),
        this.Ns(t.cards, s, t.last_full_sync_at, t.last_card_updated_at),
        this.yt.St(this.Us(!0));
    }
  }
  Fs(t) {
    this.h.B(o.q.As, t);
  }
  Ls(t, i, e) {
    const h = () => {
        this.Is(i, e, !0);
      },
      n = C(t);
    let a, l;
    if ((this.Js(), !n["retry-after"])) return void this.Fs(0);
    if (((a = n["retry-after"]), isNaN(a) && !isNaN(Date.parse(a))))
      (l = Date.parse(a) - new Date().getTime()), l < 0 && h();
    else {
      if (isNaN(parseFloat(a))) {
        const t =
          "Received unexpected value for retry-after header in /sync response";
        return s.G(r.A.qs, { e: t + ": " + a }), void this.Fs(0);
      }
      l = 1e3 * a;
    }
    this.Gt = setTimeout(() => {
      h();
    }, l);
    let c = this.h.I(o.q.As);
    (c && !isNaN(parseInt(c))) || (c = 0), this.Fs(parseInt(c) + 1);
  }
  Bt(t) {
    if (!this.Ts()) return;
    this.zt();
    const s = this.Zt.slice(),
      i = this.v.getUserId();
    for (let e = 0; e < t.length; e++)
      if (i === t[e].userId || (null == i && null == t[e].userId)) {
        const i = t[e].card;
        let h = null;
        for (let t = 0; t < this.Zt.length; t++)
          if (i.id === this.Zt[t].id) {
            h = this.Zt[t];
            break;
          }
        if (null == h) {
          const t = N(i);
          null != t && s.push(t);
        } else {
          if (!h.ct(i))
            for (let t = 0; t < s.length; t++)
              if (i.id === s[t].id) {
                s.splice(t, 1);
                break;
              }
        }
      }
    (this.Zt = this.Cs(this.ws(s, !1))), this.Ss(), this.yt.St(this.Us(!0));
  }
  ws(t, s) {
    const i = this.h.I(o.q.$) || {},
      e = this.h.I(o.q.O) || {},
      h = this.h.I(o.q.K) || {},
      r = {},
      n = {},
      a = {};
    for (let s = 0; s < t.length; s++)
      i[t[s].id] && ((t[s].clicked = !0), (r[t[s].id] = !0)),
        e[t[s].id] && ((t[s].viewed = !0), (n[t[s].id] = !0)),
        h[t[s].id] && ((t[s].dismissed = !0), (a[t[s].id] = !0));
    return s && (this.h.B(o.q.$, r), this.h.B(o.q.O, n), this.h.B(o.q.K, a)), t;
  }
  Cs(t) {
    const s = [],
      i = new Date(),
      e = this.h.I(o.q.K) || {};
    let h = !1;
    for (let n = 0; n < t.length; n++) {
      const o = t[n].url;
      !this.wt && o && j(o)
        ? r.D.error(
            `Card with url ${o} will not be displayed because Javascript URLs are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable this card.`
          )
        : (null == t[n].expiresAt || t[n].expiresAt >= i) && !t[n].dismissed
        ? s.push(t[n])
        : ((e[t[n].id] = !0), (h = !0));
    }
    return h && this.h.B(o.q.K, e), s;
  }
  Ss() {
    const t = [];
    for (let s = 0; s < this.Zt.length; s++) t.push(this.Zt[s].ss());
    this.h.B(o.q.Yt, t);
  }
  Js() {
    this.Gt && (clearTimeout(this.Gt), (this.Gt = null));
  }
  Is(t, s, i) {
    if ((i || (this.Js(), this.Fs(0)), !this.Ts()))
      return void (
        this.Es ||
        (this.Es = this.gt.Ms(() => {
          this.Is(t, s);
        }))
      );
    const h = this.vt.Ps({}, !0);
    this.h.I(o.q._s) !== e.Ds() && this.$s(),
      (h.last_full_sync_at = this.Ut),
      (h.last_card_updated_at = this.kt);
    const r = this.vt.Bs(h, !0, !1, i);
    this.vt.Gs(h, () => {
      b.Hs({
        url: this.vt.Ks() + "/content_cards/sync",
        data: h,
        headers: r,
        S: (i, e) => {
          this.vt.Os(h, i, r)
            ? (this.vt.Qs(),
              this.Ls(e, t, s),
              this.Rs(i),
              "function" == typeof t && t())
            : "function" == typeof s && s();
        },
        error: t => {
          this.vt.Vs(t, "retrieving content cards"),
            "function" == typeof s && s();
        }
      });
    });
  }
  Us(t) {
    t || this.zt();
    const s = this.Cs(this.Zt);
    s.sort((t, s) =>
      t.pinned && !s.pinned
        ? -1
        : s.pinned && !t.pinned
        ? 1
        : t.updated > s.updated
        ? -1
        : s.updated > t.updated
        ? 1
        : 0
    );
    let i = Math.max(this.kt || 0, this.Ut || 0);
    return (
      0 === i && (i = void 0),
      this.h.I(o.q.ys) === this.kt && void 0 === i && (i = this.kt),
      new ContentCards(s, h(i))
    );
  }
  Ws(t) {
    return this.yt.ut(t);
  }
  $s() {
    (this.Ut = 0), (this.kt = 0), this.h.Xs(o.q.vs), this.h.Xs(o.q.ys);
  }
  changeUser(t) {
    t ||
      ((this.Zt = []),
      this.yt.St(new ContentCards(this.Zt.slice(), null)),
      this.h.Xs(o.q.Yt),
      this.h.Xs(o.q.$),
      this.h.Xs(o.q.O),
      this.h.Xs(o.q.K)),
      this.$s();
  }
  clearData(t) {
    (this.Ut = 0),
      (this.kt = 0),
      (this.Zt = []),
      this.yt.St(new ContentCards(this.Zt.slice(), null)),
      t &&
        (this.h.Xs(o.q.Yt),
        this.h.Xs(o.q.$),
        this.h.Xs(o.q.O),
        this.h.Xs(o.q.K),
        this.h.Xs(o.q.vs),
        this.h.Xs(o.q.ys));
  }
  Ts() {
    return !!this.gt.Ys() || (0 !== this.gt.Zs() && this.ti(), !1);
  }
  ti() {
    this.yt.St(new ContentCards([], new Date().valueOf())), this.h.Xs(o.q.Yt);
  }
}
