export const Browsers = {
  rE: "Chrome",
  eE: "Edge",
  Pg: "Internet Explorer",
  EE: "Opera",
  kg: "Safari",
  oE: "Firefox"
};
export const OperatingSystems = {
  Fg: "Android",
  ln: "iOS",
  jg: "Mac",
  Dg: "Windows"
};
