import e from "../managers/braze-instance.js";
import s from "../common/event-logger.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as X,
  validateCustomString as Y
} from "../util/validation-utils.js";
export function logCustomEvent(t, o) {
  if (!e.rr()) return !1;
  if (null == t || t.length <= 0)
    return (
      r.D.error(
        `logCustomEvent requires a non-empty eventName, got "${t}". Ignoring event.`
      ),
      !1
    );
  if (!Y(t, "log custom event", "the event name")) return !1;
  const [n, i] = X(
    o,
    "logCustomEvent",
    "eventProperties",
    `log custom event "${t}"`,
    "event"
  );
  if (!n) return !1;
  if (e.ir().ge(t))
    return r.D.info(`Custom Event "${t}" is blocklisted, ignoring.`), !1;
  const m = s.G(r.A.CustomEvent, { n: t, p: i });
  if (m.S) {
    r.D.info(`Logged custom event "${t}".`);
    for (const e of m.ve) W.er().je(V.be, [t, o], e);
  }
  return m.S;
}
