import {
  convertMsToSeconds as l,
  dateFromUnixTimestamp as h
} from "../../util/date-utils.js";
import { FEED_ANIMATION_DURATION as E } from "../../common/constants.js";
import u from "../../managers/subscription-manager.js";
export default class Card {
  constructor(t, i, s, n, l, h, e, r, E, u, T, o, I, a, N, S) {
    (this.id = t),
      (this.viewed = i || !1),
      (this.title = s || ""),
      (this.imageUrl = n),
      (this.description = l || ""),
      (this.created = h || null),
      (this.updated = e || null),
      (this.categories = r || []),
      (this.expiresAt = E || null),
      (this.url = u),
      (this.linkText = T),
      (o = parseFloat(o)),
      (this.aspectRatio = isNaN(o) ? null : o),
      (this.extras = I),
      (this.pinned = a || !1),
      (this.dismissible = N || !1),
      (this.dismissed = !1),
      (this.clicked = S || !1),
      (this.isControl = !1),
      (this.test = !1),
      (this.lt = null),
      (this.Et = null);
  }
  subscribeToClickedEvent(t) {
    return this.Tt().ut(t);
  }
  subscribeToDismissedEvent(t) {
    return this.It().ut(t);
  }
  removeSubscription(t) {
    this.Tt().removeSubscription(t), this.It().removeSubscription(t);
  }
  removeAllSubscriptions() {
    this.Tt().removeAllSubscriptions(), this.It().removeAllSubscriptions();
  }
  dismissCard() {
    if (this.dismissible && !this.dismissed) {
      this.tt && this.tt(this);
      var t = this.X;
      t &&
        ((t.style.height = t.offsetHeight + "px"),
        (t.className = t.className + " ab-hide"),
        setTimeout(function() {
          t &&
            t.parentNode &&
            ((t.style.height = "0"),
            (t.style.margin = "0"),
            setTimeout(function() {
              t && t.parentNode && t.parentNode.removeChild(t);
            }, Card.Nt));
        }, E));
    }
  }
  Tt() {
    return null == this.lt && (this.lt = new u()), this.lt;
  }
  It() {
    return null == this.Et && (this.Et = new u()), this.Et;
  }
  R() {
    this.viewed = !0;
  }
  k() {
    (this.viewed = !0), (this.clicked = !0), this.Tt().St();
  }
  J() {
    return (
      !(!this.dismissible || this.dismissed) &&
      ((this.dismissed = !0), this.It().St(), !0)
    );
  }
  ct(t) {
    if (null == t || t[Card.At.ns] !== this.id) return !0;
    if (t[Card.At.Dt]) return !1;
    if (
      null != t[Card.At.us] &&
      null != this.updated &&
      t[Card.At.us] < l(this.updated.valueOf())
    )
      return !0;
    if (
      (t[Card.At.ls] && !this.viewed && (this.viewed = !0),
      t[Card.At.gs] && !this.clicked && (this.clicked = t[Card.At.gs]),
      null != t[Card.At.ht] && (this.title = t[Card.At.ht]),
      null != t[Card.At.os] && (this.imageUrl = t[Card.At.os]),
      null != t[Card.At.rt] && (this.description = t[Card.At.rt]),
      null != t[Card.At.us])
    ) {
      const i = h(t[Card.At.us]);
      null != i && (this.updated = i);
    }
    if (null != t[Card.At.ps]) {
      let i;
      (i = t[Card.At.ps] === Card.Ct ? null : h(t[Card.At.ps])),
        (this.expiresAt = i);
    }
    if (
      (null != t[Card.At.URL] && (this.url = t[Card.At.URL]),
      null != t[Card.At.bs] && (this.linkText = t[Card.At.bs]),
      null != t[Card.At.fs])
    ) {
      const i = parseFloat(t[Card.At.fs]);
      this.aspectRatio = isNaN(i) ? null : i;
    }
    return (
      null != t[Card.At.xs] && (this.extras = t[Card.At.xs]),
      null != t[Card.At.js] && (this.pinned = t[Card.At.js]),
      null != t[Card.At.zs] && (this.dismissible = t[Card.At.zs]),
      null != t[Card.At.ks] && (this.test = t[Card.At.ks]),
      !0
    );
  }
}
(Card.Ct = -1),
  (Card.es = {
    st: "captioned_image",
    dt: "text_announcement",
    Rt: "short_news",
    rs: "banner_image",
    _t: "control"
  }),
  (Card.At = {
    ns: "id",
    ls: "v",
    zs: "db",
    Dt: "r",
    us: "ca",
    js: "p",
    ps: "ea",
    xs: "e",
    ts: "tp",
    os: "i",
    ht: "tt",
    rt: "ds",
    URL: "u",
    bs: "dm",
    fs: "ar",
    gs: "cl",
    ks: "t"
  }),
  (Card.hs = {
    ns: "id",
    ls: "v",
    zs: "db",
    cs: "cr",
    us: "ca",
    js: "p",
    ds: "t",
    ps: "ea",
    xs: "e",
    ts: "tp",
    os: "i",
    ht: "tt",
    rt: "ds",
    URL: "u",
    bs: "dm",
    fs: "ar",
    gs: "cl",
    ks: "s"
  }),
  (Card.Lt = {
    Ot: "ADVERTISING",
    bt: "ANNOUNCEMENTS",
    ft: "NEWS",
    Pt: "SOCIAL"
  }),
  (Card.Nt = 400);
