import r from "../../shared-lib/braze-shared-lib.js";
import e from "../managers/braze-instance.js";
import ue from "../models/braze-event.js";
import t from "../models/request-result.js";
const s = {
  G: (o, n, s) => {
    const a = new t(),
      i = e.g();
    if (!i)
      return (
        r.D.info(
          `Not logging event with type "${o}" because the current session ID could not be found.`
        ),
        a
      );
    const m = i.mo();
    return (
      a.ve.push(new ue(s || e.p().getUserId(), o, new Date().valueOf(), m, n)),
      (a.S = e.l().bo(a.ve)),
      a
    );
  }
};
export default s;
