export default {
  en: {
    NO_CARDS_MESSAGE:
      "We have no updates for you at this time.<br/>Please check again later.",
    FEED_TIMEOUT_MESSAGE:
      "Sorry, this refresh timed out.<br/>Please try again later."
  },
  ar: {
    NO_CARDS_MESSAGE: "ليس لدينا أي تحديث. يرجى التحقق مرة أخرى لاحقاً",
    FEED_TIMEOUT_MESSAGE: "يرجى تكرار المحاولة لاحقا"
  },
  cs: {
    NO_CARDS_MESSAGE:
      "V tuto chvíli pro vás nemáme žádné aktualizace.<br/>Zkontrolujte prosím znovu později.",
    FEED_TIMEOUT_MESSAGE: "Prosím zkuste to znovu později."
  },
  da: {
    NO_CARDS_MESSAGE: "Vi har ingen updates.<br/>Prøv venligst senere.",
    FEED_TIMEOUT_MESSAGE: "Prøv venligst senere."
  },
  de: {
    NO_CARDS_MESSAGE:
      "Derzeit sind keine Updates verfügbar.<br/>Bitte später noch einmal versuchen.",
    FEED_TIMEOUT_MESSAGE: "Bitte später noch einmal versuchen."
  },
  es: {
    NO_CARDS_MESSAGE:
      "No tenemos actualizaciones.<br/>Por favor compruébelo más tarde.",
    FEED_TIMEOUT_MESSAGE: "Por favor inténtelo más tarde."
  },
  "es-mx": {
    NO_CARDS_MESSAGE:
      "No tenemos ninguna actualización.<br/>Vuelva a verificar más tarde.",
    FEED_TIMEOUT_MESSAGE: "Por favor, vuelva a intentarlo más tarde."
  },
  et: {
    NO_CARDS_MESSAGE:
      "Uuendusi pole praegu saadaval.<br/>Proovige hiljem uuesti.",
    FEED_TIMEOUT_MESSAGE: "Palun proovige hiljem uuesti."
  },
  fi: {
    NO_CARDS_MESSAGE:
      "Päivityksiä ei ole saatavilla.<br/>Tarkista myöhemmin uudelleen.",
    FEED_TIMEOUT_MESSAGE: "Yritä myöhemmin uudelleen."
  },
  fr: {
    NO_CARDS_MESSAGE:
      "Aucune mise à jour disponible.<br/>Veuillez vérifier ultérieurement.",
    FEED_TIMEOUT_MESSAGE: "Veuillez réessayer ultérieurement."
  },
  he: {
    NO_CARDS_MESSAGE: ".אין לנו עדכונים. בבקשה בדוק שוב בקרוב",
    FEED_TIMEOUT_MESSAGE: ".בבקשה נסה שוב בקרוב"
  },
  hi: {
    NO_CARDS_MESSAGE:
      "हमारे पास कोई अपडेट नहीं हैं। कृपया बाद में फिर से जाँच करें.।",
    FEED_TIMEOUT_MESSAGE: "कृपया बाद में दोबारा प्रयास करें।."
  },
  id: {
    NO_CARDS_MESSAGE: "Kami tidak memiliki pembaruan. Coba lagi nanti.",
    FEED_TIMEOUT_MESSAGE: "Coba lagi nanti."
  },
  it: {
    NO_CARDS_MESSAGE: "Non ci sono aggiornamenti.<br/>Ricontrollare più tardi.",
    FEED_TIMEOUT_MESSAGE: "Riprovare più tardi."
  },
  ja: {
    NO_CARDS_MESSAGE:
      "アップデートはありません。<br/>後でもう一度確認してください。",
    FEED_TIMEOUT_MESSAGE: "後でもう一度試してください。"
  },
  ko: {
    NO_CARDS_MESSAGE: "업데이트가 없습니다. 다음에 다시 확인해 주십시오.",
    FEED_TIMEOUT_MESSAGE: "나중에 다시 시도해 주십시오."
  },
  ms: {
    NO_CARDS_MESSAGE: "Tiada kemas kini. Sila periksa kemudian.",
    FEED_TIMEOUT_MESSAGE: "Sila cuba kemudian."
  },
  nl: {
    NO_CARDS_MESSAGE: "Er zijn geen updates.<br/>Probeer het later opnieuw.",
    FEED_TIMEOUT_MESSAGE: "Probeer het later opnieuw."
  },
  no: {
    NO_CARDS_MESSAGE:
      "Vi har ingen oppdateringer.<br/>Vennligst sjekk igjen senere.",
    FEED_TIMEOUT_MESSAGE: "Vennligst prøv igjen senere."
  },
  pl: {
    NO_CARDS_MESSAGE:
      "Brak aktualizacji.<br/>Proszę sprawdzić ponownie później.",
    FEED_TIMEOUT_MESSAGE: "Proszę spróbować ponownie później."
  },
  pt: {
    NO_CARDS_MESSAGE:
      "Não temos atualizações.<br/>Por favor, verifique mais tarde.",
    FEED_TIMEOUT_MESSAGE: "Por favor, tente mais tarde."
  },
  "pt-br": {
    NO_CARDS_MESSAGE:
      "Não temos nenhuma atualização.<br/>Verifique novamente mais tarde.",
    FEED_TIMEOUT_MESSAGE: "Tente novamente mais tarde."
  },
  ru: {
    NO_CARDS_MESSAGE:
      "Обновления недоступны.<br/>Пожалуйста, проверьте снова позже.",
    FEED_TIMEOUT_MESSAGE: "Пожалуйста, повторите попытку позже."
  },
  sv: {
    NO_CARDS_MESSAGE: "Det finns inga uppdateringar.<br/>Försök igen senare.",
    FEED_TIMEOUT_MESSAGE: "Försök igen senare."
  },
  th: {
    NO_CARDS_MESSAGE: "เราไม่มีการอัพเดต กรุณาตรวจสอบภายหลัง.",
    FEED_TIMEOUT_MESSAGE: "กรุณาลองใหม่ภายหลัง."
  },
  uk: {
    NO_CARDS_MESSAGE:
      "Оновлення недоступні.<br/>ласка, перевірте знову пізніше.",
    FEED_TIMEOUT_MESSAGE: "Будь ласка, спробуйте ще раз пізніше."
  },
  vi: {
    NO_CARDS_MESSAGE:
      "Chúng tôi không có cập nhật nào.<br/>Vui lòng kiểm tra lại sau.",
    FEED_TIMEOUT_MESSAGE: "Vui lòng thử lại sau."
  },
  "zh-hk": {
    NO_CARDS_MESSAGE: "暫時沒有更新.<br/>請稍候再試.",
    FEED_TIMEOUT_MESSAGE: "請稍候再試."
  },
  "zh-hans": {
    NO_CARDS_MESSAGE: "暂时没有更新.<br/>请稍后再试.",
    FEED_TIMEOUT_MESSAGE: "请稍候再试."
  },
  "zh-hant": {
    NO_CARDS_MESSAGE: "暫時沒有更新.<br/>請稍候再試.",
    FEED_TIMEOUT_MESSAGE: "請稍候再試."
  },
  "zh-tw": {
    NO_CARDS_MESSAGE: "暫時沒有更新.<br/>請稍候再試.",
    FEED_TIMEOUT_MESSAGE: "請稍候再試."
  },
  zh: {
    NO_CARDS_MESSAGE: "暂时没有更新.<br/>请稍后再试.",
    FEED_TIMEOUT_MESSAGE: "请稍候再试."
  }
};
