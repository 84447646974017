import e, { OPTIONS as T } from "../managers/braze-instance.js";
import aa from "./push-manager.js";
const ea = {
  t: !1,
  pushManager: null,
  aa: () => (
    ea.o(),
    ea.pushManager ||
      (ea.pushManager = new aa(
        e.gr(),
        e.ea(),
        e.ce(),
        e.Ks(),
        e.nn(T.na),
        e.nn(T.ra),
        e.ir(),
        e.nn(T.ta),
        e.nn(T.ia),
        e.l()
      )),
    ea.pushManager
  ),
  o: () => {
    ea.t || (e.u(ea), (ea.t = !0));
  },
  destroy: () => {
    (ea.pushManager = null), (ea.t = !1);
  }
};
export default ea;
