import kt from "./filter-set.js";
export default class Xt {
  constructor(t, s) {
    (this.productId = t), (this.Ql = s);
  }
  Hl(t) {
    if (null == this.productId || null == this.Ql) return !1;
    const s = t[0],
      i = t[1];
    return s === this.productId && this.Ql.Hl(i);
  }
  static fromJson(t) {
    return new Xt(
      t ? t.product_id : null,
      t ? kt.fromJson(t.property_filters) : null
    );
  }
  ss() {
    return { id: this.productId, pf: this.Ql.ss() };
  }
}
