import e from "../managers/braze-instance.js";
import g from "./content-cards-provider-factory.js";
export function subscribeToContentCardsUpdates(r) {
  if (!e.rr()) return;
  const t = g.er(),
    n = t.Ws(r);
  if (!t.Vt()) {
    const r = e.mr().cr(() => {
      t.Is();
    });
    t.Xt(r);
  }
  return n;
}
