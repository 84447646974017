import {
  isArray as p,
  isDate as yt,
  isObject as Jt,
  keys as rr
} from "./code-utils.js";
import { getByteLength as U } from "./string-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { toValidBackendTimeString as Nt } from "./date-utils.js";
import { BRAZE_ACTIONS as _ } from "./braze-actions.js";
export const CUSTOM_DATA_REGEX = /^[^\x00-\x1F\x22]+$/;
export const EMAIL_ADDRESS_REGEX = new RegExp(/^.+@.+\..+$/);
export const CUSTOM_PROPERTIES_MAX_SIZE_BYTES = 51200;
export const CUSTOM_PROPERTIES_MAX_SIZE_STRING = "50KB";
export const BRAZE_ACTION_URI_REGEX = /^brazeActions:\/\/v\d+\//;
export function _validatePropertyType(e) {
  const t = typeof e;
  return (
    null == e || "number" === t || "boolean" === t || yt(e) || "string" === t
  );
}
export function _validateNestedProperties(e, t) {
  if (p(e) && p(t)) {
    for (let r = 0; r < e.length && r < t.length; r++)
      if (
        (yt(e[r]) && (t[r] = Nt(e[r])), !_validateNestedProperties(e[r], t[r]))
      )
        return !1;
  } else {
    if (!Jt(e)) return _validatePropertyType(e);
    for (const r of rr(e))
      if (
        (yt(e[r]) && (t[r] = Nt(e[r])), !_validateNestedProperties(e[r], t[r]))
      )
        return !1;
  }
  return !0;
}
export function _validateEventPropertyValue(e, t, n, o) {
  let i;
  return (
    (i =
      Jt(e) || p(e)
        ? _validateNestedProperties(e, t)
        : _validatePropertyType(e)),
    i || r.D.error(`Cannot ${n} because ${o} "${e}" is invalid.`),
    i
  );
}
export function validateStandardString(e, t, n, o) {
  const i = "string" == typeof e || (null === e && o);
  return i || r.D.error(`Cannot ${t} because ${n} "${e}" is invalid.`), i;
}
export function validateCustomString(e, t, n) {
  const o =
    null != e &&
    "string" == typeof e &&
    ("" === e || e.match(CUSTOM_DATA_REGEX));
  return o || r.D.error(`Cannot ${t} because ${n} "${e}" is invalid.`), o;
}
export function validateCustomProperties(e, t, n, o, i) {
  if ((null == e && (e = {}), "object" != typeof e || p(e)))
    return (
      r.D.error(`${t} requires that ${n} be an object. Ignoring ${i}.`),
      [!1, null]
    );
  const a = JSON.stringify(e);
  if (U(a) > 51200)
    return (
      r.D.error(
        `Could not ${o} because ${n} was greater than the max size of 50KB.`
      ),
      [!1, null]
    );
  let s;
  try {
    s = JSON.parse(a);
  } catch (e) {
    return (
      r.D.error(`Could not ${o} because ${n} did not contain valid JSON.`),
      [!1, null]
    );
  }
  for (let t in e) {
    if (!validateCustomString(t, o, `the ${i} property name`))
      return [!1, null];
    const r = e[t];
    if (null == r) {
      delete e[t], delete s[t];
      continue;
    }
    yt(r) && (s[t] = Nt(r));
    if (!_validateEventPropertyValue(r, s[t], o, `the ${i} property "${t}"`))
      return [!1, null];
  }
  return [!0, s];
}
export function isValidEmail(e) {
  return (
    "string" == typeof e && null != e.toLowerCase().match(EMAIL_ADDRESS_REGEX)
  );
}
export function isValidBrazeActionJson(e) {
  if (!(_.properties.type in e)) return !1;
  switch (e[_.properties.type]) {
    case _.types.ue:
      if (_.properties.me in e) return !0;
      break;
    case _.types.logCustomEvent:
    case _.types.setEmailNotificationSubscriptionType:
    case _.types.setPushNotificationSubscriptionType:
    case _.types.setCustomUserAttribute:
    case _.types.addToSubscriptionGroup:
    case _.types.removeFromSubscriptionGroup:
    case _.types.addToCustomAttributeArray:
    case _.types.removeFromCustomAttributeArray:
    case _.types.de:
    case _.types.pe:
      if (_.properties.fe in e) return !0;
      break;
    case _.types.requestPushPermission:
      return !0;
    default:
      return !1;
  }
  return !1;
}
export function isValidBrazeActionType(e) {
  let t = !1;
  return (
    Object.keys(_.types).forEach(r => {
      _.types[r] !== e.toString() || (t = !0);
    }),
    t
  );
}
