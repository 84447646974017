import r from "../../shared-lib/braze-shared-lib.js";
export function getByteLength(e) {
  let t = e.length;
  for (let r = e.length - 1; r >= 0; r--) {
    const n = e.charCodeAt(r);
    n > 127 && n <= 2047 ? t++ : n > 2047 && n <= 65535 && (t += 2),
      n >= 56320 && n <= 57343 && r--;
  }
  return t;
}
export function decodeBrazeActions(e) {
  try {
    e = e.replace(/-/g, "+").replace(/_/g, "/");
    let t = window.atob(e),
      r = new Uint8Array(t.length);
    for (let e = 0; e < t.length; e++) r[e] = t.charCodeAt(e);
    let n = new Uint16Array(r.buffer);
    return String.fromCharCode(...n);
  } catch (e) {
    return r.D.error("Unable to decode Base64: " + e), null;
  }
}
