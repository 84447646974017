import { Card } from "./models/index.js";
import n from "./card-manager-factory.js";
import e from "../managers/braze-instance.js";
import { isArray as p } from "../util/code-utils.js";
import { MUST_BE_CARD_WARNING_SUFFIX as f } from "../common/constants.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function logCardImpressions(o, s) {
  if (!e.rr()) return !1;
  if (!p(o)) return r.D.error("cards must be an array"), !1;
  for (const s of o)
    if (!(s instanceof Card)) return r.D.error(`Each card in cards ${f}`), !1;
  return n.m().M(o, s).S;
}
