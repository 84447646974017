export default class wt {
  constructor(t, s, l, n, i) {
    (this.triggerId = t),
      (this.wi = s),
      (this.Di = l),
      (this.Si = n),
      (this.$i = i);
  }
  static fromJson(t, s, l, n, i) {
    return null == t || null == t.trigger_id
      ? null
      : new wt(t.trigger_id, s, l, n, i);
  }
}
