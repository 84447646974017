import e from "../managers/braze-instance.js";
import v from "./content-cards-provider.js";
const g = {
  t: !1,
  provider: null,
  er: () => (
    g.o(),
    g.provider ||
      ((g.provider = new v(e.p(), e.l(), e.ir(), e.tr(), e.nr())),
      e.ar(g.provider)),
    g.provider
  ),
  o: () => {
    g.t || (e.u(g), (g.t = !0));
  },
  destroy: () => {
    (g.provider = null), (g.t = !1);
  }
};
export default g;
