import b from "../util/net.js";
import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import s from "../common/event-logger.js";
import { newInAppMessageFromJson as lt } from "./in-app-message-factory.js";
import { randomInclusive as pt } from "../util/math.js";
import t from "../models/request-result.js";
import r from "../../shared-lib/braze-shared-lib.js";
import u from "../managers/subscription-manager.js";
import mt from "../triggers/models/trigger.js";
import { validateValueIsFromEnum as F } from "../util/code-utils.js";
import { BRAZE_ACTION_URI_REGEX as O } from "../util/validation-utils.js";
import {
  containsPushPrimerBrazeAction as gt,
  containsUnknownBrazeAction as ct,
  getDecodedBrazeAction as J,
  ineligibleBrazeActionURLErrorMessage as ht,
  INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES as ft
} from "../util/braze-actions.js";
import dt from "../Push/utils/push-utils.js";
export default class ge {
  constructor(t, s, i, r) {
    (this.vt = t),
      (this.j = s),
      (this.h = i),
      (this.v = r),
      (this.Pe = new u()),
      e.jt(this.Pe),
      (this.Qe = 1e3),
      (this.Ue = 6e4);
  }
  Ve() {
    return this.Pe;
  }
  We(t) {
    return this.Pe.ut(t);
  }
  Xe() {
    return this.Ye;
  }
  Ze(t) {
    this.Ye = t;
  }
  ni(e, i) {
    if (
      !F(
        InAppMessage.li,
        i,
        `${i} is not a valid in-app message display failure`,
        "InAppMessage.DisplayFailures"
      )
    )
      return new t();
    const n = { trigger_ids: [e], error_code: i };
    return s.G(r.A.ui, n);
  }
  G(e, i, n, o) {
    const a = new t();
    let l;
    if (e instanceof ControlMessage) l = { trigger_ids: [e.triggerId] };
    else {
      if (i === r.A.pi || (e instanceof HtmlMessage && i === r.A.mi)) {
        if (!e.k(o))
          return (
            r.D.info(
              "This in-app message has already received a click. Ignoring analytics event."
            ),
            a
          );
      } else if (i === r.A.gi) {
        if (!e.R())
          return (
            r.D.info(
              "This in-app message has already received an impression. Ignoring analytics event."
            ),
            a
          );
      }
      l = this.ci(e);
    }
    return null == l ? a : (null != n && (l.bid = n), s.G(i, l));
  }
  fi(e, i) {
    const n = new t();
    if (!e.k())
      return (
        r.D.info(
          "This in-app message button has already received a click. Ignoring analytics event."
        ),
        n
      );
    const o = this.ci(i);
    return null == o
      ? n
      : e.id === InAppMessageButton.di
      ? (r.D.info(
          "This in-app message button does not have a tracking id. Not logging event to Braze servers."
        ),
        n)
      : (null != e.id && (o.bid = e.id), s.G(r.A.mi, o));
  }
  vi(t) {
    if (!(t instanceof InAppMessage)) return;
    const s = t => {
        const s = J(t);
        return ct(s)
          ? ht(ft.yi, "In-App Message")
          : gt(s) && !dt.ji()
          ? ht(ft.bi, "In-App Message")
          : void 0;
      },
      e = t.buttons;
    let i;
    for (const t of e)
      if (
        t.clickAction === InAppMessage.ClickAction.URI &&
        O.test(t.uri) &&
        ((i = s(t.uri)), i)
      )
        return i;
    return t.clickAction === InAppMessage.ClickAction.URI && O.test(t.uri)
      ? s(t.uri)
      : void 0;
  }
  Ii(t, s, e, i) {
    let n = this.vt.Ai(!1, !1);
    (n = this.vt.Ps(n)),
      (n.template = { trigger_id: t.triggerId, trigger_event_type: s }),
      null != e && (n.template.data = e.Mi());
    const o = this.vt.Bs(n);
    this.vt.Gs(n, () => {
      b.Hs({
        url: `${this.vt.Ks()}/template/`,
        data: n,
        headers: o,
        S: async s => {
          if (!this.vt.Os(n, s, o))
            return (
              this.ni(t.triggerId, InAppMessage.li.zi),
              void ("function" == typeof t.Di && t.Di())
            );
          if ((this.vt.Qs(), null == s || null == s.templated_message))
            return void this.ni(t.triggerId, InAppMessage.li.zi);
          const e = s.templated_message;
          if (e.type !== mt._i.Ti)
            return void this.ni(t.triggerId, InAppMessage.li.ki);
          const i = lt(e.data);
          if (null == i) return void this.ni(t.triggerId, InAppMessage.li.ki);
          let a = this.vi(i);
          if (a)
            return r.D.error(a), void ("function" == typeof t.Di && t.Di());
          "function" == typeof t.wi
            ? t.wi(i)
            : this.ni(t.triggerId, InAppMessage.li.zi);
        },
        error: r => {
          let n = `getting user personalization for message ${t.triggerId}`;
          if (new Date().valueOf() - t.Si > t.$i)
            this.ni(t.triggerId, InAppMessage.li.zi);
          else {
            const r = Math.min(t.$i, this.Ue),
              o = this.Qe;
            null == i && (i = o);
            const a = Math.min(r, pt(o, 3 * i));
            (n += `. Retrying in ${a} ms`),
              setTimeout(() => {
                this.Ii(t, s, e, a);
              }, a);
          }
          this.vt.Vs(r, n);
        }
      });
    });
  }
  ci(t) {
    if (null == t.triggerId)
      return (
        r.D.info(
          "The in-app message has no analytics id. Not logging event to Braze servers."
        ),
        null
      );
    const s = {};
    return null != t.triggerId && (s.trigger_ids = [t.triggerId]), s;
  }
}
