import e from "../managers/braze-instance.js";
import { keys as rr } from "../util/code-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function wipeData() {
  if (null == e.l())
    throw new Error("Braze must be initialized before calling methods.");
  e.l().clearData();
  const o = rr(r.xt);
  for (let e = 0; e < o.length; e++) {
    const i = o[e],
      t = r.xt[i];
    new r.qt(t, r.D).clearData();
  }
  if (e.rr()) for (const r of e.re()) r.clearData(!0);
}
