import e, { OPTIONS as T } from "../../managers/braze-instance.js";
import { ContentCards, subscribeToContentCardsUpdates } from "../index.js";
import g from "../content-cards-provider-factory.js";
import {
  destroyFeedHtml as z,
  detectFeedImpressions as q,
  feedToHtml as I,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as L,
  refreshFeed as M,
  registerFeedSubscriptionId as $,
  updateFeedCards as k
} from "../../common/feed-display.js";
import { setCardHeight as A } from "../../Card/display/card-display.js";
import { setupFeedUI as B } from "../../ui/js/index.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export function showContentCards(n, t) {
  if (!e.rr()) return;
  B();
  let o = !1;
  null == n && ((n = document.body), (o = !0));
  const a = e.nn(T.tn) || e.nn(T.en) || !1;
  let i = g.er().Us(!1);
  "function" == typeof t && k(i, t(i.cards.slice()), i.lastUpdated, null, a);
  const s = I(i, a),
    f = g.er();
  (null == i.lastUpdated ||
    new Date().valueOf() - i.lastUpdated.valueOf() > ContentCards.ur) &&
    (null == f.Ht() || new Date().valueOf() - f.Ht() > ContentCards.ur) &&
    (r.D.info(
      `Cached content cards were older than max TTL of ${ContentCards.ur} ms, requesting an update from the server.`
    ),
    M(i, s),
    f.Qt(new Date().valueOf()));
  const l = new Date().valueOf(),
    c = subscribeToContentCardsUpdates(function(n) {
      const e = s.querySelectorAll(".ab-refresh-button")[0];
      if (null != e) {
        let n = 500;
        const t = parseInt(s.getAttribute(L));
        isNaN(t)
          ? (n -= new Date().valueOf() - l)
          : (n -= new Date().valueOf() - t),
          setTimeout(function() {
            e.className = e.className.replace(/fa-spin/g, "");
          }, Math.max(n, 0));
      }
      let o = n.cards;
      "function" == typeof t && (o = t(o.slice())),
        k(i, o, n.lastUpdated, s, a);
    });
  $(c, s);
  const u = function(n) {
    const t = n.querySelectorAll(".ab-feed");
    let e = null;
    for (let o = 0; o < t.length; o++) t[o].parentNode === n && (e = t[o]);
    null != e ? (z(e), e.parentNode.replaceChild(s, e)) : n.appendChild(s),
      setTimeout(function() {
        s.className = s.className.replace("ab-hide", "ab-show");
      }, 0),
      o && s.focus(),
      q(i, s),
      A(i.cards, n);
  };
  var m;
  null != n
    ? u(n)
    : (window.onload =
        ((m = window.onload),
        function() {
          "function" == typeof m && m(), u(document.body);
        }));
}
