export default class Wt {
  constructor(t) {
    this.productId = t;
  }
  Hl(t) {
    return null == this.productId || t[0] === this.productId;
  }
  static fromJson(t) {
    return new Wt(t ? t.product_id : null);
  }
  ss() {
    return this.productId;
  }
}
