import r from "../../shared-lib/braze-shared-lib.js";
import e from "../managers/braze-instance.js";
import {
  BRAZE_ACTIONS as _,
  getDecodedBrazeAction as J
} from "../util/braze-actions.js";
import {
  BRAZE_ACTION_URI_REGEX as O,
  isValidBrazeActionJson as P
} from "../util/validation-utils.js";
import { WindowUtils as H } from "../util/window-utils.js";
import { getUser as Q } from "./get-user.js";
export function _handleBrazeAction(a, s, i) {
  if (e.rr())
    if (O.test(a)) {
      const e = J(a);
      if (!e) return;
      const s = async e => {
        if (!P(e))
          return void r.D.error(
            `Decoded Braze Action json is invalid: ${JSON.stringify(
              e,
              null,
              2
            )}`
          );
        const a = e[_.properties.type];
        if (a === _.types.ue) for (const r of e[_.properties.me]) s(r);
        else {
          const s = e[_.properties.fe];
          let i, o;
          switch (a) {
            case _.types.logCustomEvent:
              (i = await import("./log-custom-event.js")),
                i.logCustomEvent.apply(null, Array.prototype.slice.call(s));
              break;
            case _.types.requestPushPermission:
              (i = await import("../Push/request-push-permission.js")),
                i.requestPushPermission();
              break;
            case _.types.setEmailNotificationSubscriptionType:
            case _.types.setPushNotificationSubscriptionType:
            case _.types.setCustomUserAttribute:
            case _.types.addToSubscriptionGroup:
            case _.types.removeFromSubscriptionGroup:
            case _.types.addToCustomAttributeArray:
            case _.types.removeFromCustomAttributeArray:
              (o = Q()), o[a].apply(o, Array.prototype.slice.call(s));
              break;
            case _.types.de:
            case _.types.pe:
              H.openUri.apply(null, Array.prototype.slice.call(s));
              break;
            default:
              r.D.info(`Ignoring unknown Braze Action: ${a}`);
          }
        }
      };
      s(e);
    } else H.openUri(a, i, s);
}
export function handleBrazeAction(e, r) {
  _handleBrazeAction(e, r);
}
