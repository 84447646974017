import { createCloseButton as i } from "../../util/component-utils.js";
import { detectSwipe as d, DIRECTIONS as c } from "../../util/dom-utils.js";
import { _handleBrazeAction as m } from "../../Core/handle-braze-action.js";
import { logCardDismissal } from "../index.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export const TOP_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-top-impression";
export const BOTTOM_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-bottom-impression";
export function topHadImpression(t) {
  return null != t && !!t.getAttribute("data-ab-had-top-impression");
}
export function impressOnTop(t) {
  null != t && t.setAttribute("data-ab-had-top-impression", !0);
}
export function bottomHadImpression(t) {
  return null != t && !!t.getAttribute("data-ab-had-bottom-impression");
}
export function impressOnBottom(t) {
  null != t && t.setAttribute("data-ab-had-bottom-impression", !0);
}
export function markCardAsRead(t) {
  if (null != t) {
    const o = t.querySelectorAll(".ab-unread-indicator")[0];
    null != o && (o.className += " read");
  }
}
export function getCardId(t) {
  return t.getAttribute("data-ab-card-id");
}
export function _setImageAltText(t, o) {
  let a = "";
  t.title || t.description || (a = "Feed Image"), o.setAttribute("alt", a);
}
export function setCardHeight(t, o) {
  const a = o.querySelectorAll(".ab-image-area");
  let e,
    n = 0;
  a.length > 0 && (n = a[0].offsetWidth);
  for (const o of t)
    if (((e = o.X), e && o.imageUrl && !isNaN(o.aspectRatio))) {
      const t = n / o.aspectRatio;
      t && (e.style.height = `${t}px`);
    }
}
export function cardToHtml(t, logCardClick, o) {
  const a = document.createElement("div");
  (a.className = "ab-card ab-effect-card " + t.Y),
    a.setAttribute("data-ab-card-id", t.id),
    a.setAttribute("role", "article"),
    a.setAttribute("tabindex", "0");
  const e = t.url && "" !== t.url,
    n = n => (markCardAsRead(a), e && (logCardClick(t), m(t.url, n, o)), !1);
  if (t.pinned) {
    const t = document.createElement("div");
    t.className = "ab-pinned-indicator";
    const o = document.createElement("i");
    (o.className = "fa fa-star"), t.appendChild(o), a.appendChild(t);
  }
  if (t.imageUrl && "" !== t.imageUrl) {
    const o = document.createElement("div");
    o.className = "ab-image-area";
    const i = document.createElement("img");
    if (
      (i.setAttribute("src", t.imageUrl),
      (i.onload = () => {
        a.style.height = "auto";
      }),
      _setImageAltText(t, i),
      o.appendChild(i),
      (a.className += " with-image"),
      e && !t.Z)
    ) {
      const e = document.createElement("a");
      e.setAttribute("href", t.url),
        (e.onclick = n),
        e.appendChild(o),
        a.appendChild(e);
    } else a.appendChild(o);
  }
  const s = document.createElement("div");
  if (((s.className = "ab-card-body"), t.dismissible)) {
    t.tt = () => logCardDismissal(t);
    const o = i("Dismiss Card", void 0, t.dismissCard.bind(t));
    a.appendChild(o),
      d(s, c.ot, t => {
        (a.className += " ab-swiped-left"), o.onclick(t);
      }),
      d(s, c.et, t => {
        (a.className += " ab-swiped-right"), o.onclick(t);
      });
  }
  const u = t.title && "" !== t.title;
  if (u) {
    const o = document.createElement("h1");
    if (
      ((o.className = "ab-title"),
      (o.id = r.it.nt()),
      a.setAttribute("aria-labelledby", o.id),
      e)
    ) {
      const a = document.createElement("a");
      a.setAttribute("href", t.url),
        (a.onclick = n),
        a.appendChild(document.createTextNode(t.title)),
        o.appendChild(a);
    } else o.appendChild(document.createTextNode(t.title));
    s.appendChild(o);
  }
  const p = document.createElement("div");
  if (
    ((p.className = u ? "ab-description" : "ab-description ab-no-title"),
    (p.id = r.it.nt()),
    a.setAttribute("aria-describedby", p.id),
    p.appendChild(document.createTextNode(t.description)),
    e)
  ) {
    const o = document.createElement("div");
    o.className = "ab-url-area";
    const a = document.createElement("a");
    a.setAttribute("href", t.url),
      a.appendChild(document.createTextNode(t.linkText)),
      (a.onclick = n),
      o.appendChild(a),
      p.appendChild(o);
  }
  s.appendChild(p), a.appendChild(s);
  const b = document.createElement("div");
  return (
    (b.className = "ab-unread-indicator"),
    t.viewed && (b.className += " read"),
    a.appendChild(b),
    (t.X = a),
    a
  );
}
