const Ae = {
  nt: () => {
    const t = t => {
      const e = (Math.random().toString(16) + "000000000").substr(2, 8);
      return t ? "-" + e.substr(0, 4) + "-" + e.substr(4, 4) : e;
    };
    return t() + t(!0) + t(!0) + t();
  }
};
export default Ae;
