import { isDate as yt } from "./code-utils.js";
export function convertMsToSeconds(e, n) {
  let t = e / 1e3;
  return n && (t = Math.floor(t)), t;
}
export function convertSecondsToMs(e) {
  return 1e3 * e;
}
export function dateFromUnixTimestamp(e) {
  const n = parseInt(e);
  return null == e || isNaN(n) ? null : new Date(1e3 * n);
}
export function toValidBackendTimeString(e) {
  return null != e && yt(e) ? e.toISOString().replace(/\.[0-9]{3}Z$/, "") : e;
}
export function rehydrateDateAfterJsonization(e) {
  return null == e || "" === e ? null : new Date(e);
}
export function timestampOrNow(e) {
  return null == e || "" === e ? new Date().valueOf() : e;
}
export function secondsAgo(e) {
  return (new Date().valueOf() - e.valueOf()) / 1e3;
}
export function secondsInTheFuture(e) {
  return (e.valueOf() - new Date().valueOf()) / 1e3;
}
