import InAppMessage from "./in-app-message.js";
import u from "../../managers/subscription-manager.js";
export default class InAppMessageButton {
  constructor(s, t, i, r, e, h, n) {
    (this.text = s || ""),
      (this.backgroundColor = t || InAppMessage.xi.Ci),
      (this.textColor = i || InAppMessage.xi.Ei),
      (this.borderColor = r || this.backgroundColor),
      (this.clickAction = e || InAppMessage.ClickAction.NONE),
      (this.uri = h),
      null == n && (n = InAppMessageButton.di),
      (this.id = n),
      (this.nl = !1),
      (this.lt = new u());
  }
  subscribeToClickedEvent(s) {
    return this.lt.ut(s);
  }
  removeSubscription(s) {
    this.lt.removeSubscription(s);
  }
  removeAllSubscriptions() {
    this.lt.removeAllSubscriptions();
  }
  k() {
    return !this.nl && ((this.nl = !0), this.lt.St(), !0);
  }
  static fromJson(s) {
    return new InAppMessageButton(
      s.text,
      s.bg_color,
      s.text_color,
      s.border_color,
      s.click_action,
      s.uri,
      s.id
    );
  }
}
InAppMessageButton.di = -1;
